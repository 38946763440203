import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Logo from "../images/logo.svg"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { curriculumMenu: true }
  }

  toggleMenu = () => {
    this.setState({
      curriculumMenu: !this.state.curriculumMenu,
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query Math {
            math: allCourses(filter: { subject_name: { eq: "MATH" } }) {
              nodes {
                url
                nav_title
              }
            }
            sss: allCourses(filter: { subject_name: { eq: "SSS" } }) {
              nodes {
                url
                nav_title
              }
            }
            ela: allCourses(filter: { subject_name: { eq: "ELA" } }) {
              nodes {
                url
                nav_title
              }
            }
          }
        `}
        render={data => (
          <nav className="navigation z-50">
            <div className="container mx-auto flex flex-row align-center items-center h-full justify-between relative z-10">
              <div className="w-auto z-10">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Match Fishtank Logo"
                    className="nav-logo"
                  ></img>
                </Link>
              </div>
              <div className="flex flex-row w-3/5">
                <div className="m-4 z-10">
                  <Link to="/about" className="nav-item">
                    About Us
                  </Link>
                </div>
                <div
                  className={`m-4 ${
                    this.state.curriculumMenu ? "hide-menu" : ""
                  }`}
                  onMouseOver={() => this.toggleMenu()}
                  onMouseOut={() => this.toggleMenu()}
                >
                  <Link to="/curriculum" className="nav-item">
                    Curriculum
                  </Link>
                  <div className={`hiddenMenu flex flex-row w-auto`}>
                    <div className="hiddenMenu-item">
                      <p className="w-full hiddenMenu-header">
                        <Link to="/curriculum/science-and-social-studies/">
                          Science and Social Studies
                        </Link>
                      </p>
                      <div className="hiddenMenu-sub flex flex-col">
                        {data.sss.nodes.map((obj, i) => {
                          return (
                            <Link to={obj.url} key={i}>
                              {obj.nav_title}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                    <div className="hiddenMenu-item z-0">
                      <p className="w-full hiddenMenu-header">
                        <Link to="/curriculum/english-language-arts/">
                          English Language Arts
                        </Link>
                      </p>
                      <div className="hiddenMenu-sub flex flex-col">
                        {data.ela.nodes.map((obj, i) => {
                          return (
                            <Link to={obj.url} key={i}>
                              {obj.nav_title}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                    <div className="hiddenMenu-item">
                      <p className="w-full hiddenMenu-header">
                        <Link to="/curriculum/mathematics/">Mathematics</Link>
                      </p>
                      <div className="hiddenMenu-sub flex flex-col">
                        {data.math.nodes.map((obj, i) => {
                          return (
                            <Link to={obj.url} key={i}>
                              {obj.nav_title}
                            </Link>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-4">
                  <Link to="/teacher-tools" className="nav-item">
                    Teacher Tools
                  </Link>
                </div>
              </div>
              <div className="flex flex-row justify-between w-2/5">
                <div>
                  <Link to="/fishtank-plus" className="btn-nav">
                    Upgrade to Plus
                  </Link>
                </div>
                <div>
                  <Link to="/login" className="nav-item">
                    Login
                  </Link>
                </div>
                <div>
                  <Link to="/register" className="btn-nav-white">
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        )}
      />
    )
  }
}

export default Header
