import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <>
    <footer className="footer flex flex-col">
      <div className="container flex flex-row py-8 mx-auto">
        <div className="w-3/5">
          <p className="text-white">
            Welcome to <strong>Match Fishtank</strong>, where you can{" "}
            <strong>view, share, and download</strong> the curriculum we use
            every day at Match Charter Public School, the PreK-12 charter public
            school that we opened 20 years ago in Boston.
          </p>
          <p className="text-white">
            These materials have been developed and curated by our{" "}
            <strong>teachers and curriculum experts</strong> over many years.
          </p>
          <p className="text-white">
            Want more ideas and inspiration for implementing Match Fishtank
            curriculum?{" "}
            <Link to="/blog" className="text-white">
              Visit our blog
            </Link>{" "}
            for thoughts on lesson planning, professional development, and the
            latest news in education
          </p>
        </div>
        <div className="flex flex-row w-2/5"></div>
      </div>
    </footer>
    <div className="flex flex-row w-full py-2 footer-dark-blue w-full">
      <div className="flex flex-row w-full container mx-auto">
        <div className="flex flex-row w-3/5 justify-between">
          <Link className="text-white underline">Privacy Policy</Link>
          <Link className="text-white underline">Terms of Use</Link>
          <Link className="text-white underline">Contact Us</Link>
        </div>
      </div>
    </div>
  </>
)

export default Footer
